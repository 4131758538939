import * as React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Rent from "assets/images/illustrations/rent.png";
import House from "assets/images/illustrations/house.png";
import Car from "assets/images/illustrations/car.png";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <section id={"our-services"}>
      <Container
        sx={{
          width: "100%",
          maxWidth: "100% !important",
          //display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Box p={2}>
          <Typography
            variant="h1"
            fontWeight="900"
            textTransform="capitalize"
            fontFamily={"Inter, system-ui"}
            textAlign={"center"}
            mb={2}
            sx={{ color: "rgb(36, 62, 99)" }}
          >
            Our Services
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "block", padding: 5 }}>
              <Box
                sx={{
                  width: 180,
                  height: 180,
                  margin: "auto",
                  backgroundImage: `url(${Rent})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <Box textAlign={"center"}>
                <Typography
                  variant="h3"
                  fontWeight="700"
                  textTransform="capitalize"
                  fontFamily={"Inter, system-ui"}
                  mb={1}
                  mt={2}
                  sx={{ color: "rgb(36, 62, 99)" }}
                >
                  Pick up
                </Typography>
                <Typography
                  variant="button"
                  fontWeight="normal"
                  textTransform="none"
                  fontFamily={"Inter, system-ui"}
                  sx={{ color: "#7b809a" }}
                >
                  We offer transport services from airport to grocery store
                  or/and home. Tell us what you need and let us do the leg work!
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "block", padding: 5 }}>
              <Box
                sx={{
                  width: 180,
                  height: 180,
                  margin: "auto",
                  backgroundImage: `url(${Car})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <Box textAlign={"center"}>
                <Typography
                  variant="h3"
                  fontWeight="700"
                  textTransform="capitalize"
                  fontFamily={"Inter, system-ui"}
                  mb={1}
                  mt={2}
                  sx={{ color: "rgb(36, 62, 99)" }}
                >
                  Car Rental
                </Typography>
                <Typography
                  variant="button"
                  fontWeight="normal"
                  textTransform="none"
                  fontFamily={"Inter, system-ui"}
                  sx={{ color: "#7b809a" }}
                >
                  coming soon
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "block", padding: 5 }}>
              <Box
                sx={{
                  width: 180,
                  height: 180,
                  margin: "auto",
                  backgroundImage: `url(${House})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <Box textAlign={"center"}>
                <Typography
                  variant="h3"
                  fontWeight="700"
                  textTransform="capitalize"
                  fontFamily={"Inter, system-ui"}
                  mb={1}
                  mt={2}
                  sx={{ color: "rgb(36, 62, 99)" }}
                >
                  Accomodation
                </Typography>
                {/* <Link to={"/homestay"}>
                  <Typography
                    variant="button"
                    fontWeight="700"
                    fontFamily={"Inter, system-ui"}
                    sx={{ color: "#fa6d4d", cursor: "pointer" }}
                  >
                    Learn More
                  </Typography>
                </Link> */}
                <Typography
                  variant="button"
                  fontWeight="normal"
                  textTransform="none"
                  fontFamily={"Inter, system-ui"}
                  sx={{ color: "#7b809a" }}
                >
                  coming soon
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Services;
