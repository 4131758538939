import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Travel from "assets/images/illustrations/travel1.png";

const AboutUs = () => {
  return (
    <section id="about-us">
      <Container
        sx={{
          bgcolor: "#fa6d4d",
          width: "100%",
          display: { xs: "block", md: "flex" },
          maxWidth: "100% !important",
          justifyContent: "center",
          alignItems: "center",
          padding: 5,
        }}
      >
        <Box p={2}>
          <Typography
            variant="h1"
            fontWeight="900"
            textTransform="capitalize"
            textAlign={"center"}
            fontFamily={"Inter, system-ui"}
            mb={2}
            sx={{ color: "white !important" }}
          >
            About Us
          </Typography>
          <Typography
            variant="button"
            fontWeight="600"
            textTransform="none"
            fontFamily={"Inter, system-ui"}
            mb={2}
            sx={{
              color: "white !important",
              fontSize: 20,
              textAlign: "center",
            }}
          >
            We Auckland Malaysians are keen to kickstart your exciting working
            holiday journey with you.
          </Typography>
        </Box>
        <Box
          sx={{
            height: "400px",
            width: { xs: "100%", md: "100%", lg: "50%" },
            maxWidth: "100% !important",
            backgroundImage: `url(${Travel})`,
            backgroundSize: "auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></Box>
      </Container>
    </section>
  );
};

export default AboutUs;
