import React from "react";
import { DefaultTheme, makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

const useStyles = makeStyles((theme: DefaultTheme) => ({
  section: {},
  cardHeader: {
    marginTop: "2rem",
  },
}));

export default function Pricing(props) {
  const classes = useStyles();

  const content = {
    badge: "LOREM IPSUM",
    "header-p1": "Our Pricing",
    "header-p2": "turpis non sapien lobortis pretium",
    "01_title": "1 Pax",
    "01_price": "$60",
    "01_suffix": " / pax",
    "01_benefit1": "Pick up from airport",
    "01_benefit2": "Only within Auckland area",
    "01_benefit3": "Include grocery shopping (only between 8am-10pm)",
    "01_primary-action": "Select plan",
    "01_secondary-action": "Learn more",
    "02_title": "2 Pax",
    "02_price": "$45",
    "02_suffix": " / pax",
    "02_benefit1": "Pick up from airport",
    "02_benefit2": "Only within Auckland area",
    "02_benefit3": "Include grocery shopping (only between 8am-10pm)",
    "03_title": "3 Pax (Max per car)",
    "03_price": "$40",
    "03_suffix": "/ pax",
    "03_benefit1": "Pick up from airport",
    "03_benefit2": "Only within Auckland area",
    "03_benefit3": "Include grocery shopping (only between 8am-10pm)",
    "04_title": "After 11pm",
    "04_price": "$65",
    "04_suffix": " / pax",
    "04_benefit1": "2 Pax: $50 per pax",
    "04_benefit2": "3 Pax: $45 per pax",
    "04_benefit3": "Not include groceries",

    ...props.content,
  };

  return (
    <section className={classes.section} id="pricing">
      <Container maxWidth="lg">
        <Box py={8} textAlign="center">
          <Box mb={3}>
            <Container maxWidth="sm">
              <Typography
                variant="h1"
                fontWeight="900"
                textTransform="capitalize"
                fontFamily={"Inter, system-ui"}
                mb={2}
                sx={{ color: "rgb(36, 62, 99)" }}
              >
                {content["header-p1"]}
              </Typography>
            </Container>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Card variant="outlined" sx={{ height: 400 }}>
                <CardHeader
                  title={content["01_title"]}
                  className={classes.cardHeader}
                ></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content["01_price"]}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="span"
                      >
                        {content["01_suffix"]}
                      </Typography>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["01_benefit1"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["01_benefit2"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["01_benefit3"]}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card variant="outlined" sx={{ height: 400 }}>
                <CardHeader
                  title={content["02_title"]}
                  className={classes.cardHeader}
                ></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content["02_price"]}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="span"
                      >
                        {content["02_suffix"]}
                      </Typography>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit1"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit2"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit3"]}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card variant="outlined" sx={{ height: 400 }}>
                <CardHeader
                  title={content["03_title"]}
                  className={classes.cardHeader}
                ></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content["03_price"]}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="span"
                      >
                        {content["03_suffix"]}
                      </Typography>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["03_benefit1"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["03_benefit2"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["03_benefit3"]}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card variant="outlined" sx={{ height: 400 }}>
                <CardHeader
                  title={content["04_title"]}
                  className={classes.cardHeader}
                ></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content["04_price"]}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="span"
                      >
                        {content["04_suffix"]}
                      </Typography>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["04_benefit1"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["04_benefit2"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["04_benefit3"]}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
