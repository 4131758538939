import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MainLogo from "assets/images/logos/mainlogo.png";
import { Scrollchor } from "react-scrollchor";
import { Link } from "react-router-dom";

const pages = [
  {
    name: "Our Services",
    link: "our-services",
  },
  {
    name: "About Us",
    link: "about-us",
  },
  {
    name: "Pricing",
    link: "pricing",
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="absolute">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={"/"}>
            <Box
              sx={{
                height: 120,
                width: 140,
                backgroundImage: `url(${MainLogo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                mr: 2,
                display: { xs: "none", md: "flex" },
              }}
            />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon color={"action"} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  color="white"
                >
                  <Scrollchor to={page.link}>
                    <Typography textAlign="center" color="white">
                      {page.name}
                    </Typography>
                  </Scrollchor>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              height: 120,
              width: 140,
              backgroundImage: `url(${MainLogo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",

              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          />
          <Box ml={4} sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Scrollchor to={page.link} key={page.name}>
                <Button
                  color="inherit"
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "white !important",
                    display: "block",
                    fontWeight: "normal",
                    fontSize: 16,
                  }}
                >
                  {page.name}
                </Button>
              </Scrollchor>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="contact us">
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  color: "white !important",
                  borderRadius: 20,
                }}
                href={"mailto:wintatt@hotmail.com"}
              >
                Contact Us
              </Button>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
