import styled from "styled-components";

export const AutocompletePlaceResults = styled.ul`
  position: absolute;
  background-color: white;
  padding: 0;
  margin: 0;
  border: 1px solid #00000011;
  z-index: 1000;
  overflow: auto;
  height: 15rem;
`;

export const AutocompletePlaceItems = styled.li`
  list-style: none;
  border: 1px solid #00000011;
  cursor: pointer;
  padding: 10px 10px;
  z-index: 1000;
`;
