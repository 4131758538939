import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MainLogo from "assets/images/logos/mainlogo.png";

const Footer = () => {
  return (
    <Container
      sx={{
        bgcolor: "rgba(26,32,44,1)",
        width: "100%",
        display: { xs: "block", md: "flex" },
        maxWidth: "100% !important",
        justifyContent: "center",
        alignItems: "center",
        padding: 5,
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={1.5}
      >
        <Box
          sx={{
            height: 100,
            width: 140,
            backgroundImage: `url(${MainLogo})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Link target="_blank">
          <Typography
            variant="button"
            fontWeight="500"
            sx={{ color: "white !important", fontSize: "0.875rem" }}
          >
            &copy; {new Date().getFullYear()}, made by WIN & WONG ENTERPRISE.
            All Rights Reserved.
          </Typography>
        </Link>
      </Box>
    </Container>
  );
};

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.carching.co/", name: "Carching" },
  links: [
    { href: "https://www.facebook.com/carchingco", name: "Facebook" },
    { href: "https://www.instagram.com/carching.co/", name: "Instagram" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
