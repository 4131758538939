import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import RoysPeak1 from "assets/images/royspeak1.jpeg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import DatePicker from "@mui/lab/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { collection, addDoc } from "firebase/firestore";
import { Formik, Form as FormikForm } from "formik";
import * as Styled from "./hero.styled";
import { availableTimes } from "./time";
import db from "../../firebase";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

export interface Appointment {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  noPerson: number;
  dateTime: any;
  time: any;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
const Hero = () => {
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const [appointmentDetails, setAppointment] = useState<Appointment>(
    {} as Appointment
  );
  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResult, setResult] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const searchLocations = async () => {
      setLoadingStatus(true);
      const { data } = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?country=nz&access_token=${"pk.eyJ1IjoibWMxMDBzIiwiYSI6ImNqb2E2ZTF3ODBxa3czd2xldHp1Z2FxbGYifQ.U4oatm5RsTXXHQLz5w66dQ"}`
      );
      setResult(data.features);
      setLoadingStatus(false);
    };
    if (searchValue === "") {
      return;
    }
    const timerId = setTimeout(() => {
      searchLocations();
      // make a request after 1 second since there's no typing
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [search, searchValue]);

  const initialValues = {
    name: "",
    email: "",
    noPerson: 0,
    phoneNumber: "",
    dateTime: null,
    time: "",
  };
  const handleItemClicked = (place: any) => {
    setSearch(place.place_name);
    setSearchValue("");
    setResult([]);
  };
  const onSubmit = async (values) => {
    const { name, email, noPerson, phoneNumber, dateTime, time } = values;
    if (
      !!name &&
      !!email &&
      noPerson > 0 &&
      !!phoneNumber &&
      !!dateTime &&
      !!time &&
      !!search
    ) {
      const appoinment: Appointment = {
        name,
        email,
        noPerson,
        phoneNumber,
        dateTime: dateTime.toLocaleDateString(),
        time,
        address: search,
      };
      setAppointment(appoinment);
      handleOpen();
    } else {
      alert("All fields are required");
    }
  };

  const submitRequest = async () => {
    setIsSubmitting(true);
    try {
      const docRef = await addDoc(
        collection(db, "appointment"),
        appointmentDetails
      );
      alert(
        "A confirmation message will send to your phone number within 24 hours"
      );
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <Container
      sx={{
        bgcolor: "#cfe8fc",
        height: { xs: "155vh", md: "90vh" },
        width: "100%",
        maxWidth: "100% !important",
        backgroundImage: `url(${RoysPeak1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4} sx={{ marginTop: "80px" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box p={2} sx={{ alignItems: "center", justifyContent: "center" }}>
            <Typography
              variant="h1"
              fontWeight="900"
              textTransform="capitalize"
              mb={2}
              textAlign="center"
              fontFamily={"Inter, system-ui"}
              sx={{ color: "white !important" }}
            >
              {"Smooth Airport Transfer"}
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight="500"
              textTransform="none"
              textAlign="center"
              mb={2}
              fontFamily={"Inter, system-ui"}
              sx={{ color: "white !important" }}
            >
              {
                "Make your journey from the airport smooth and hassle free within Auckland region"
              }
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 0,
          }}
        >
          <Box p={2}>
            <Card
              sx={{
                height: { xs: 650, md: 600 },
                width: { xs: "auto", md: 500 },
                margin: "auto",
              }}
            >
              <CardContent>
                <Container sx={{ padding: 5 }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                      textAlign: "left",
                      color: "#7b809a",
                    }}
                    color="text"
                    fontFamily={"Inter, system-ui"}
                    gutterBottom
                  >
                    WE ARE HERE FOR YOU
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                    color="dark"
                    gutterBottom
                  >
                    Make your appointment now!
                  </Typography>
                  <Formik
                    autoComplete="off"
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleBlur, handleChange, setFieldValue }) => (
                      <FormikForm>
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                          <TextField
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            label="Name"
                            variant="outlined"
                            autoComplete="new-name"
                            fullWidth
                          />
                        </Box>
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                          <TextField
                            type="text"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            label="Email"
                            variant="outlined"
                            fullWidth
                          />
                        </Box>
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                          <TextField
                            type="text"
                            name="phoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            label="Phone Number"
                            autoComplete="new-number"
                            variant="outlined"
                            fullWidth
                          />
                        </Box>
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                          <TextField
                            type="text"
                            name="address"
                            autoComplete="new-address"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setSearchValue(e.target.value);
                            }}
                            label="Destination"
                            variant="outlined"
                            fullWidth
                          />
                          {!!searchResult && searchResult.length > 0 && (
                            <Styled.AutocompletePlaceResults>
                              {searchResult.map((place) => (
                                <Styled.AutocompletePlaceItems
                                  style={{ color: "#7b809a", fontSize: "16px" }}
                                  key={place.id}
                                  onClick={() => handleItemClicked(place)}
                                >
                                  <p>{place.place_name}</p>
                                </Styled.AutocompletePlaceItems>
                              ))}
                              {isLoading && (
                                <Styled.AutocompletePlaceItems>
                                  Loading...
                                </Styled.AutocompletePlaceItems>
                              )}
                            </Styled.AutocompletePlaceResults>
                          )}
                        </Box>
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                          <DatePicker
                            label="Pick up date"
                            value={values.dateTime}
                            onChange={(newValue) => {
                              setFieldValue("dateTime", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginTop: 2,
                            marginBottom: 2,
                            display: "flex",
                          }}
                        >
                          <TextField
                            type="number"
                            name="noPerson"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.noPerson}
                            label="No of persons"
                            variant="outlined"
                          />

                          <Box sx={{ marginLeft: 1, minWidth: 60 }}>
                            <FormControl fullWidth>
                              <InputLabel id="time-select-label">
                                Time
                              </InputLabel>
                              <Select
                                labelId="time-select-label"
                                name="time"
                                value={values.time}
                                label="Time"
                                autoWidth
                                sx={{ height: "45px" }}
                                onChange={handleChange}
                              >
                                {availableTimes.map((time) => (
                                  <MenuItem key={time} value={time}>
                                    {time}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Button
                          color="secondary"
                          variant="contained"
                          sx={{
                            marginTop: 2,
                            color: "white !important",
                            borderRadius: 20,
                          }}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </FormikForm>
                    )}
                  </Formik>
                </Container>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Please confirm all the details are correct before submit
          </Typography>
          {!!appointmentDetails && (
            <>
              <Box sx={{ display: "flex", marginTop: 2 }}>
                <Typography variant="caption" sx={{ fontWeight: 600, mr: 2 }}>
                  Name:
                </Typography>
                <Typography variant="caption">
                  {appointmentDetails.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: 2 }}>
                <Typography variant="caption" sx={{ fontWeight: 600, mr: 2 }}>
                  Email:
                </Typography>
                <Typography variant="caption">
                  {appointmentDetails.email}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: 2 }}>
                <Typography variant="caption" sx={{ fontWeight: 600, mr: 2 }}>
                  Contact Number:
                </Typography>
                <Typography variant="caption">
                  {appointmentDetails.phoneNumber}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: 2 }}>
                <Typography variant="caption" sx={{ fontWeight: 600, mr: 2 }}>
                  Destination:
                </Typography>
                <Typography variant="caption">
                  {appointmentDetails.address}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: 2 }}>
                <Typography variant="caption" sx={{ fontWeight: 600, mr: 2 }}>
                  No. of persons:
                </Typography>
                <Typography variant="caption">
                  {appointmentDetails.noPerson}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: 2 }}>
                <Typography variant="caption" sx={{ fontWeight: 600, mr: 2 }}>
                  Date & Time:
                </Typography>
                <Typography variant="caption">
                  {appointmentDetails.dateTime} - {appointmentDetails.time}
                </Typography>
              </Box>
              {isSubmitting && (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
              {!isSubmitting && (
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    marginTop: 2,
                    color: "white !important",
                    borderRadius: 20,
                  }}
                  onClick={submitRequest}
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};
export default Hero;
