import pxToRem from "assets/theme/functions/pxToRem";

const dialogAction = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
    },
  },
};

export default dialogAction;
