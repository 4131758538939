import typography from "assets/theme/base/typography";

const { fontWeightMedium } = typography;

const tab = {
  styleOverrides: {
    root: {
      fontWeight: fontWeightMedium,
      textTransform: "none",
    },
  },
};

export default tab;
