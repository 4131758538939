//import HomeStay from "layout/homeStay";
import Landing from "layout/landing";

const routes = [
  {
    type: "collapse",
    name: "Landing",
    key: "landing",
    route: "/",
    component: <Landing />,
  },
  // {
  //   type: "collapse",
  //   name: "HomeStay",
  //   key: "homestay",
  //   route: "/homestay",
  //   component: <HomeStay />,
  // },
];

export default routes;
