import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCeEk-catn3gFhIMiz76CGSJsPbox-B2HM",
  authDomain: "7app.firebaseapp.com",
  databaseURL: "https://7app.firebaseio.com",
  projectId: "app-df521",
  storageBucket: "app-df521.appspot.com",
  messagingSenderId: "425032428729",
  appId: "1:425032428729:web:d8bbc29133d3e01d48bf68",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
