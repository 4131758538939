const tabRoot = {
  styleOverrides: {
    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },
  },
};

export default tabRoot;
