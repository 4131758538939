import React from "react";
import Pricing from "features/Pricing";
import Services from "features/Services";
import Footer from "features/Footer";
import AboutUs from "features/AboutUs";
import Hero from "features/Hero";
import ResponsiveAppBar from "features/AppBar";

const Landing: React.FC = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Hero />
      <Services />
      <AboutUs />
      <Pricing />

      <Footer />
    </>
  );
};

export default Landing;
