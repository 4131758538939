import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// Material Dashboard 2 React routes
import routes from "routes";

const AppWrapperRoute: React.FC = () => {
  const getRoutes = (allRoutes: any) =>
    allRoutes.map((route: any) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });
  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppWrapperRoute;
